@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');


html {
  scroll-behavior: smooth; /* Ensure smooth scrolling */
}


body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  direction: ltr; /* Default to left-to-right */
}


body[dir='rtl'] {
  direction: rtl; /* Apply right-to-left when Arabic is selected */
  text-align: right;
}

body[dir="rtl"] h1,
body[dir="rtl"] h4,
body[dir="rtl"] h5,
body[dir="rtl"] h6,
body[dir="rtl"] p,
body[dir="rtl"]{
  text-align: right; /* Ensures text aligns to the right in all block elements */
}

body[dir="rtl"] .we-build,
body[dir="rtl"] .we-collaborate {
  direction: rtl;  /* Make text direction RTL */
  text-align: right; /* Align text to the right */
}
/* Initial SVG class for both directions */
.svg-flip {
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

/* In RTL mode, flip the SVG */
body[dir="rtl"] .svg-flip {
  transform: scaleX(-1); /* Flip the SVG horizontally */
}

/* Hover effect: move the SVG to the left in RTL */
body[dir="rtl"] .svg-flip:hover {
  transform: scaleX(-1) translateX(-0.5rem); /* Move to the left in RTL */
}

/* Keep the original hover effect in LTR */
body[dir="ltr"] .svg-flip:hover {
  transform: scaleX(1) translateX(0.5rem); /* Move to the right in LTR */
}

.hero {
  /* background: url(./images/hero-bg.svg) top center no-repeat; */
  background-size: cover;
  /* display: flex; */
  align-items: center;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}
